"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filePanelProfile = void 0;
exports.filePanelProfile = {
    name: "filePanel",
    displayName: "File explorers",
    description: "Provides communication between remix file explorers and remix-plugin",
    location: "sidePanel",
    documentation: "",
    version: "0.0.1",
    methods: ['getCurrentWorkspace', 'getWorkspaces', 'createWorkspace', 'renameWorkspace', 'registerContextMenuItem'],
    events: ['setWorkspace', 'renameWorkspace', 'deleteWorkspace', 'createWorkspace', 'customAction']
};
